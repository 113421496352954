import React from "react"
import data from "@components/pageLegal/data/dataPageCookies"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Description from "@components/pageLegal/components/description"

const StructureCulqui = ({ location }) => {
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true
  return (
    <div className="fnd">
      <section>
      <BannerCookies />
      <Header
        path="/nosotros/postulacion"
        location={location}
        windowsWidth={windowsWidth}
      />
        <section className="container">
          <Description
            data={data.description}
            data2={data.options}
            location={location}
          />
        </section>
      </section>
    </div>
  )
}

export default StructureCulqui
